import React from 'react';

import './about.scss';

const About = () => {
    return (
        <section className="about" id="about">
            <div className="container">
                <div className="hero__hands">
                    <div className="circle hero__hands-circle-1" />
                    <div className="circle hero__hands-circle-2" />
                    <p className="g-subtitle" data-aos="">Democratizing Access</p>
                    <div className="hero__hands-row">
                        <p className="g-h3" data-aos="fade-up" data-aos-delay="300">Algorithmic trading should not be accessible to only hedge funds and elite programmers.</p>
                        <p className="g-p1" data-aos="fade-up" data-aos-delay="600">Aristotle is working to democratize access to these powerful tools for everyday people.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;