import React from 'react';
import Plx from 'react-plx';

import Icon1 from '../../assets/images/vision-icon-1.inline';
import Icon2 from '../../assets/images/vision-icon-2.inline.js';
import Icon3 from '../../assets/images/vision-icon-3.inline.js';

import { parallaxCircles2 } from '../../utils'

import './our-vision.scss'

const parallaxRotate = [
    {
        start: '.vision',
        end: '.footer',
        properties: [
            {
                startValue: -30,
                endValue: 30,
                unit: 'deg',
                property: 'rotate',
            },
            {
                startValue: 0,
                endValue: 500,
                property: 'translateY',
            },
        ],
    },
];

const OurVision = () => {
    return (
        <section className="vision" id="services">
            <div className="container">
                <Plx parallaxData={ parallaxRotate } className="vision__circles" />
                <Plx parallaxData={ parallaxCircles2 } className="vision__circle" />

                <div className="row">
                    <div className="col-xs-12 col-md-8 col-md-offset-2">
                        <p className="g-subtitle" data-aos="">Our vision</p>
                        <h2 className="g-h2" data-aos="fade-up" data-aos-delay="300">Quickly and easily create whatever trading strategy you have in mind</h2>
                        <p className="g-p1" data-aos="fade-up" data-aos-delay="600">Currently supporting technical and social indicators, with Aristotle you will be able to trade based off the weather, political climate, charts, when Elon Musk tweets about some coin, or really whatever condition you can think of. </p>
                    </div>
                </div>
                <div className="row vision__icons">
                    <div className="col-xs-12 col-sm-4">
                        <div className="vision__icons-icon" data-aos="">
                            <Icon1 />
                        </div>
                        <p className="g-p1 color-white" data-aos="" data-aos-delay="75"><span>No Code</span></p>
                        <p className="g-p2" data-aos="fade-up" data-aos-delay="150">No more spreadsheets, no more manual trades. Create dynamic strategies with a no-code visual editor. Watch your trades execute automatically.</p>
                    </div>``
                    <div className="col-xs-12 col-sm-4">
                        <div className="vision__icons-icon" data-aos="" data-aos-delay="300">
                            <Icon2 />
                        </div>
                        <p className="g-p1 color-white" data-aos="" data-aos-delay="375"><span>Templates</span></p>
                        <p className="g-p2" data-aos="fade-up" data-aos-delay="450">Discover Aristotle’s collection of ready-made strategy templates.</p>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="vision__icons-icon" data-aos="" data-aos-delay="600">
                            <Icon3 />
                        </div>
                        <p className="g-p1 color-white" data-aos="" data-aos-delay="675"><span>Drag and Drop</span></p>
                        <p className="g-p2" data-aos="fade-up" data-aos-delay="750">Connect your brokerage or crypto account to make live trades using our scratch like interface. Build your portfolio by investing in strategies. Monitor performance and watch them trade live.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurVision;
