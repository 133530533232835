import React, { useState, useEffect }  from "react";

import "../assets/stylesheets/shared.scss";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Header from "../components/header";
import Hero from "../components/hero";
import About from "../components/about";
import OurVision from "../components/our-vision";
import Technologies from "../components/technologies";
import Hire from "../components/hire";
import GetInTouch from "../components/get-in-touch";
import Footer from "../components/footer";

const IndexPage = () => {

    useEffect(() => {
     var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
  // Huawei Phone must come first because its UA also contains "Android"
  //  if (userAgent.includes("Android")) {
  //     window.location.replace("https://play.google.com/store/apps/details?id=com.daedalusfi");
  // } else if (userAgent.includes("iPhone")) {
  //     window.location.replace("https://testflight.apple.com/join/r0G4P7dR");
  // }
    });
  
  return (
    <Layout>
      <Seo
        title="Aristotle"
        description="Build trading bots without needing to know how to code"
        keywords={[]}
      />
      <Header />
      <Hero />
      <About />
      <OurVision />

      <GetInTouch />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
